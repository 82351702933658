import React, { useEffect, useState } from 'react';
import { useLoaderData, Link } from 'react-router-dom';
import { PageData } from "../types/PageData";
import SliderSingle from '../components/SliderSingle';
import Slider from 'react-slick';
import NextSection from '../components/NextSection';
import CountUp from 'react-countup';
import {Helmet} from 'react-helmet';

import nextArrow from '../assets/images/icons/The-Odd-Number-Arrow-Right-Light.svg';
import prevArrow from '../assets/images/icons/The-Odd-Number-Arrow-Left-Light.svg';
import Cta from '../components/Cta';
import Navigation from '../components/Navigation';
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";
import ShareLinks from '../components/ShareLinks';
import {motion} from "framer-motion";

function extractVideoId(video: string): string | null {
    const match = video.match(/youtube\.com\/embed\/(\w+)/i);
    if (match) {
      return match[1];
    } else {
      return null;
    }
}

function WorkSingle() {
    const [url, setUrl] = useState('');
    const PageData = useLoaderData() as PageData
    const itemsArray:any = PageData;
    const items = itemsArray[0].content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;
    let videoId:any = '';
    let videoBanner:any = '';

    const imgRegex = /.*src="([^"]*)".*/;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

    const singleSliderSettings:any = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
        nextArrow: <span className={'ai-slider-arrow ai-slider-arrow-next'}><img src={nextArrow} alt='' /> <img className={'slick-arrow-transition'} src={nextArrow} alt='' /></span>,
        prevArrow: <span className={'ai-slider-arrow ai-slider-arrow-next'}><img src={prevArrow} alt='' /> <img src={prevArrow} alt='' className={'slick-arrow-transition'}/></span>,
    }

    useEffect(() => {
        setUrl(window.location.href);
        window.scrollTo(0, 0);
    }, [items]);

    const [inView, setInView] = useState(false);
    const containerRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        // window.location.reload();
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [containerRef]);

    if(items[7][4] === undefined || items[7][4] === '' || null) {
        videoId = '';
    } else {
        videoId = extractVideoId(items[7][4][0]);
    }
console.log(items)
  return (
    <div className='single-work'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+itemsArray[0].title.rendered}</title>
        </Helmet>
        <Navigation />
        <div className='text-banner'>
            <div className={`container ${inView && 'animating'}`} ref={containerRef}>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 text-banner__heading">
                        {items[0] !== undefined && <motion.h1 initial={{opacity: 0, x: '-10%'}}
                                                   style={{overflow: 'hidden'}}
                                                   animate={inView && {opacity: 1, x: '0'}}
                                                   transition={{duration: 1}}
                                                   dangerouslySetInnerHTML={{__html: items[0][0][0]}} />}
                        <ShareLinks
                            url={url}
                            title={itemsArray[0].title.rendered}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 text-banner__content">
                        {items[0] !== undefined && <Heading Heading={items[0][1][0]} Tag={'h2'} />}
                        {items[0] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[0][2][0] }} />}
                        {items[0] !== undefined && <Paragraph Paragraphs={items[0][2][1]} />}

                    </div>
                </div>
            </div>
        </div>

        {/* <div className="work-intro">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        {items[1] !== undefined && <Paragraph Paragraphs={items[1][0][0] } />}
                        {items[1] !== undefined && <Paragraph Paragraphs={items[1][0][1] } />}
                    </div>
                    <div className="col-12 col-lg-6">
                        {items[1] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][0] }} />}
                        {items[1] !== undefined && <Paragraph Paragraphs={items[1][1][1] } />}

                        <div className="logos">
                            <div className="logo">
                                <div className="image">
                                    {items[1][1][1] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][1] }} />}
                                </div>
                                <div className="text">
                                    {items[1][1][2] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][2] }} />}
                                    {items[1][1][9]['paragraphs'][0] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][9]['paragraphs'][0] }} />}
                                </div>
                            </div>

                            <div className="logo">
                                <div className="image">
                                    {items[1][1][3] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][3] }} />}
                                </div>
                                <div className="text">
                                    {items[1][1][4] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][4] }} />}
                                    {items[1][1][9]['paragraphs'][1] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][9]['paragraphs'][1] }} />}
                                </div>
                            </div>

                            <div className="logo">
                                <div className="image">
                                    {items[1][1][5] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][5] }} />}
                                </div>
                                <div className="text">
                                    {items[1][1][6] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][6] }} />}
                                    {items[1][1][9]['paragraphs'][2] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][9]['paragraphs'][2] }} />}
                                </div>
                            </div>

                            <div className="logo">
                                <div className="image">
                                    {items[1][1][7] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][7] }} />}
                                </div>
                                <div className="text">
                                    {items[1][1][8] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][8] }} />}
                                    {items[1][1][9]['paragraphs'][3] !== undefined && <div dangerouslySetInnerHTML={{ __html: items[1][1][9]['paragraphs'][3] }} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="container observations">
            <div className="row">
                <div className="col-12">
                    {items[1] !== undefined && <Heading Heading={items[1][0][0]} Tag={'h2'} />}
                    {items[1] !== undefined && <Paragraph Paragraphs={items[1][1]} />}

                </div>
            </div>
        </div>

        {items[2][0] !== 'ai_ending_inner_row ' && <div className="work-slider">
            <div className="container">
                <div className="row ai-pb-1">
                    <div className="col-12 col-md-6 col-lg-6">
                        {items[2] !== undefined && <Paragraph Paragraphs={items[2][0][0]}/>}
                        {items[2] !== undefined && <Paragraph Paragraphs={items[2][0][1]} />}
                    </div>
                </div>
            </div>
            <div className="component single-slider-wrapper">
                <div className="container">
                    <Slider {...singleSliderSettings}>
                        {items[2][1].map((item:any, index:any) => (
                            typeof item !== 'object' && <SliderSingle 
                            image={item.replace(imgRegex, '$1')}
                            />
                        ))}
                        {/* {items[2][1] !== undefined ? (
                        <SliderSingle 
                            image={items[2][1][0].replace(imgRegex, '$1')}
                        /> ) : null }
                        {items[2][1] !== undefined ? (
                        <SliderSingle 
                            image={items[2][1][1].replace(imgRegex, '$1')}
                        /> ) : null}
                        {items[2][1] !== undefined ? (
                        <SliderSingle 
                            image={items[2][1][2].replace(imgRegex, '$1')}
                        /> ) : null }
                        {items[2][1][3] !== undefined ? (
                        <SliderSingle 
                            image={items[2][1][3].replace(imgRegex, '$1')}
                        /> ) : null }
                        {items[2][1] !== undefined ? (
                        <SliderSingle 
                            image={items[2][1][4].replace(imgRegex, '$1')}
                        /> ) : null } */}
                    </Slider>
                </div>

                {items[4] !== undefined &&<div className="container">
                    <NextSection 
                        title='CONCEPT'
                        sectionAnchor='#concept'
                        color='light'
                    />
                </div> }
            </div>
        </div> }

        {items[4] !== undefined && <div id="concept">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {items[3] !== undefined && <Heading Heading={items[3][0][0]} Tag={'h2'} />}
                        {items[3] !== undefined && <Paragraph Paragraphs={items[3][0][1]} />}
                    </div>
                </div>
            </div>
            {/*<div className="container results">
                <div className="row">
                    <div className="col-12">
                        {items[4] !== undefined ? ( <Heading Heading={items[4][0][0]} Tag={'h2'} /> ) : null }
                    </div>
                    {items[4] !== undefined ? (
                    <div className="col-12 col-lg-3">
                        <p className='counter'>
                            {
                                items[4][1][0] ? <span>
                                    +<CountUp 
                                start={50} 
                                end={items[4][1][0]} 
                                duration={2} 
                                /> %
                                </span>:
                                <span>50%</span>
                            }
                        </p>
                        {items[4] !== undefined ? ( <Paragraph Paragraphs={items[4][2][1]} /> ) : null }
                    </div> ) : null }
                    {items[4] !== undefined ? (
                    <div className="col-12 col-lg-3 d-none">
                        <p className='counter'>
                            {
                                items[4][2][0] ? <span>
                                    +<CountUp 
                                start={50} 
                                end={items[4][2][0]} 
                                duration={2} 
                                /> %
                                </span>:
                                <span>50%</span>
                            }
                        </p>
                        {items[4][3][1] !== undefined ? ( <Paragraph Paragraphs={items[4][3]} /> ) : null}
                    </div> ) : null }
                    {items[4] !== undefined ? (
                    <div className="col-12 col-lg-4 d-none">
                        <p className='counter'>
                            {
                                items[4][3][0] ? <span>
                                    <CountUp 
                                start={50} 
                                end={items[4][3][0]} 
                                duration={2} 
                                /> %
                                </span>:
                                <span>50%</span>
                            }
                        </p>
                        {items[4][4].paragraphs !== undefined ? ( <Paragraph Paragraphs={items[4][4].paragraphs[0]} /> ) : null }
                        {items[4][4][1] !== undefined ? <Paragraph Paragraphs={items[4][4][1].paragraphs[0]} /> : null}
                    </div>) : null }
                </div>
                        </div>*/}
        </div> }

        <div className="image-banner">
            {items[5][0] !== 'ai_ending_inner_row ' && <div><iframe src={`https://www.youtube.com/embed/${items[5][0]}?&autoplay=1&mute=1&loop=1&controls=0&rel=0&playlist=${items[5][0]}&showinfo=0&modestbranding=1`} title="TON Web with mute final" allow='autoplay; encrypted-media; picture-in-picture'></iframe>
            <img src={items[5][1].replace(imgRegex, '$1')} alt="" className="mobile-placeholder" /></div> }
        </div>
        
        {items[6] !== undefined && <Cta 
            background={items[6] !== undefined ? items[6][0].replace(imgRegex, '$1') : ''}
            title={items[6] !== undefined ? items[6][1][0] : ''}
            buttonHref={items[6] !== undefined ? items[6][2][0].replace(aiButtonHref, '$1') : ''}
            buttonText={items[6] !== undefined ? items[6][2][0].replace(aiButtonHref, '$2') : ''}
            copy={items[6] !== undefined ? items[6][2][2]['paragraphs'][0] : ''}
        /> }
        {items[7] !== undefined && <Link onClick={window.location.reload} to={items[7][2][0].replace(aiButtonHref, '$1')} className="brand-cta">
            {videoId === '' ? '' : <iframe src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&mute=1&loop=1&controls=0&rel=0&playlist=${videoId}&showinfo=0&modestbranding=1`} title="TON Web with mute final" allow='autoplay; encrypted-media; picture-in-picture'></iframe> }
            <div className='cta' style={{backgroundImage: `url(${items[7] !== undefined ? items[7][0].replace(imgRegex, '$1') : ''})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 heading">
                            {items[7] !== undefined && <div className='subtitle' dangerouslySetInnerHTML={{__html: items[7][3][0]}} />}
                            {items[7] !== undefined && <Heading Tag={'h2'} Heading={items[7][1][0]} />}
                        </div>
                        <div className="col-12 col-md-6 content">
                            {items[7] !== undefined && <Paragraph Paragraphs={items[7][2][2]['paragraphs'][0]} className={'copy'} />}
                            {items[7] !== undefined && <div className='btn btn__dark'>{items[7][2][0].replace(aiButtonHref, '$2')}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </Link> }
    </div>
  )
}

export default WorkSingle