import React, { useState, useEffect } from 'react'
import { useLoaderData, Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { PageData } from '../types/PageData';
import TeamSlider from '../components/TeamSlider';
import TextBanner from '../components/TextBanner';
import SingleWorkBanner from '../components/SingleWorkBanner';
import VideoBgBanner from '../components/VideoBgBanner';
import NextSection from '../components/NextSection';
import VideoModal from '../components/VideoModal';
import Accordion from '../components/Accordion';
import SliderSingle from '../components/SliderSingle';
import Slider from 'react-slick';
import {Helmet} from 'react-helmet';

import game from '../assets/images/logos/The-Odd-Number-Game-colour.svg';
import patternBg from '../assets/images/The-Odd-Number-Home-Pattern-Dark.jpg';
import SelectedProducts from '../components/SelectedProducts';

import nextArrow from '../assets/images/icons/The-Odd-Number-Arrow-Right-Light.svg';
import prevArrow from '../assets/images/icons/The-Odd-Number-Arrow-Left-Light.svg';
import ContentImage from '../components/ContentImage';
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";


function Home() {
    const [selectedChild, setSelectedChild] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;

    const regex = /(<([^>]+)>)/ig;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const imgAltRegex = /.*src="([^"]*)".*alt="([^"]*)".*/
    const imgRegex = /.*src="([^"]*)".*/;

    const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };

    const singleSliderSettings:any = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: false,
        useCSS: false,
        nextArrow: <span className={'ai-slider-arrow ai-slider-arrow-next'}><img src={nextArrow} alt='' /> <img className={'slick-arrow-transition'} src={nextArrow} alt='' /></span>,
        prevArrow: <span className={'ai-slider-arrow ai-slider-arrow-next'}><img src={prevArrow} alt='' /> <img src={prevArrow} alt='' className={'slick-arrow-transition'}/></span>,
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);
    // console.log(items);
  return (
    <div className={`home ${loading ? 'loading' : ''}`}>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <div className="component">
            <VideoBgBanner
                setLoading = {setLoading}
                loading = {loading}
                video={items[0][0]}
                placeholder={items[0][2][0].replace(imgRegex, '$1')}
            />
            <div className="container">
                <NextSection
                    title='WHO WE ARE'
                    sectionAnchor='#who-we-are'
                    color='light'
                />
            </div>
        </div>

        <div id='who-we-are' className="component" style={{backgroundImage: `url(${items[1][0].replace(imgRegex, '$1')})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <Heading Heading={items[1][1][0]} Tag={'h2'}/>
                        {items[1][2] && <Paragraph Paragraphs={items[1][2]}/>}
                    </div>
                </div>
            </div>
            <div className="container">
                <NextSection
                    title='SELECTED PROJECTS'
                    sectionAnchor='#selected-projects'
                    color='light'
                />
            </div>
        </div>

        <div id='selected-projects' className="component" style={{backgroundImage: `url(${items[2][0].replace(imgRegex, '$1')})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-5">
                      <Heading Heading={items[2][1][0]} Tag={'h2'}/>
                    </div>
                    <div className="col-12 col-md-7">

                        <Paragraph Paragraphs={items[2][2][3]['paragraphs']+items[2][2].join('').replace('[object Object]', '')}/>
                        {/*{items[2][2][3] && <div dangerouslySetInnerHTML={{__html: items[2][2][3]['paragraphs']+items[2][2].join('').replace('[object Object]', '')}} />}*/}

                        <div className="logos">
                            <div className="logo">
                                {items[2][3][0] && <img src={items[2][3][0].replace(imgRegex, '$1')} alt={items[2][3][0].replace(imgAltRegex, '$2')} />}
                                {items[2][3][1] && <div dangerouslySetInnerHTML={{__html: items[2][3][1]+items[2][3][2]+'</p>'}} />}
                            </div>
                            <div className="logo">
                                {items[2][3][3] && <img src={items[2][3][3].replace(imgRegex, '$1')} alt={items[2][3][3].replace(imgAltRegex, '$2')} />}
                                {items[2][3][4] && <div dangerouslySetInnerHTML={{__html: items[2][3][4]+items[2][3][5]+'</p>'}} />}
                            </div>
                            <div className="logo">
                                {items[2][3][6] && <img src={items[2][3][6].replace(imgRegex, '$1')} alt={items[2][3][6].replace(imgAltRegex, '$2')} />}
                                {items[2][3][7] && <div dangerouslySetInnerHTML={{__html: items[2][3][7]+items[2][3][8]+'</p>'}} />}
                            </div>
                            <div className="logo">
                                {items[2][3][9] && <img src={items[2][3][9].replace(imgRegex, '$1')} alt={items[2][3][9].replace(imgAltRegex, '$2')} />}
                                {items[2][3][10] && <div dangerouslySetInnerHTML={{__html: items[2][3][10]+items[2][3][11]+'</p>'}} />}
                            </div>
                            <div className="logo">
                                {items[2][3][12] && <img src={items[2][3][12].replace(imgRegex, '$1')} alt={items[2][3][12].replace(imgAltRegex, '$2')} />}
                                {items[2][3][13] && <div dangerouslySetInnerHTML={{__html: items[2][3][13]+items[2][3][14]+'</p>'}} />}
                            </div>
                            <div className="logo">
                                {items[2][3][15] && <img src={items[2][3][15].replace(imgRegex, '$1')} alt={items[2][3][15].replace(imgAltRegex, '$2')} />}
                                {items[2][3][16] && <div dangerouslySetInnerHTML={{__html: items[2][3][16]+items[2][3][17]+'</p>'}} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SelectedProducts
            background={items[3][0].replace(imgRegex, '$1')}
            category={items[3][1][0]}
            title={items[3][2][0]}
            description={items[3][3][2]}
            buttonHref={items[3][3][0]}
            videoId={items[3][4][0]}
        />
        
        <SelectedProducts
            background={items[4][0].replace(imgRegex, '$1')}
            category={items[4][1][0]}
            title={items[4][2][0]}
            description={items[4][3][2]}
            buttonHref={items[4][3][0]}
            videoId={items[4][4][0]}
        />

        <div className="component single-slider-wrapper" style={{backgroundImage: `url(${items[1][0].replace(imgRegex, '$1')})`}}>
            <div className="container">
                <Slider {...singleSliderSettings}>
                    <SliderSingle
                        image={items[5][0].replace(imgRegex, '$1')}
                        title={items[5][1][0]}
                        subtitle={items[5][2][0]}
                        buttonHref={items[5][3][0]}
                    />

                    <SliderSingle
                        image={items[5][4][0].replace(imgRegex, '$1')}
                        title={items[5][5][0]}
                        subtitle={items[5][6][0]}
                        buttonHref={items[5][7][0]}
                    />

                    <SliderSingle
                        image={items[5][8][0].replace(imgRegex, '$1')}
                        title={items[5][9][0]}
                        subtitle={items[5][10][0]}
                        buttonHref={items[5][11][0]}
                    />
                </Slider>
            </div>
            <div className="container">
                <NextSection
                    title='OUR SERVICES'
                    sectionAnchor='#our-services'
                    color='light'
                />
            </div>
        </div>

        <div id="our-services">
          <div className="intro-text">
            <ContentImage
                layout='right text'
                buttonHref={items[6][0][0]}
                copy={items[6][0][2]}
            />
          </div>
            <ContentImage
                layout='left text'
                headingSize='large'
                buttonHref={items[7][1][0]}
                title={items[7][0][0]}
                copy={items[7][1][2]}
            />
        </div>

        <div className="container logos-wrapper">
            <div className="row">
                <div className="col-12">
                    <div className="logos">
                        {items[8][0][0] && <div dangerouslySetInnerHTML={{__html: items[8][0][0]}} />}
                    </div>
                    <div className="link text-md-center">
                        <Link to='/services#our-clients' className='btn btn__dark'>VIEW ALL BRANDS WE HAVE WORKED ON</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home