import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import { Link } from 'react-router-dom';
import ShareLinks from '../components/ShareLinks';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";

function NewsSingle() {
    const [url, setUrl] = useState('');
    const PageData = useLoaderData() as PageData
    const itemsArray:any = PageData;
    const items = itemsArray[0];
    const title = items.title.rendered;
    const postMedia:any = items._embedded;
    const postDate = new Date(items.date);
    const options:any = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(postDate);
    const postImage = postMedia['wp:featuredmedia'][0].media_details.sizes.full.source_url;
    const imageTitle = postMedia['wp:featuredmedia'][0].media_details.sizes.full.file;
    const postCategory = postMedia['wp:term'][0][0].name;
    const imgRegex = /.*src="([^"]*)".*/;
    const postContent = items.content.no_short_codes;

    const yoast:any = PageData.yoast_head_json;

    useEffect(() => {
        setUrl(window.location.href);

        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [items]);
      console.log(items);
  return (
    <div className='single-post'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+title}</title>
        </Helmet>
        <Navigation />
        <div className="single-post-banner" style={{ backgroundImage: `url(${postContent[0][0].replace(imgRegex, '$1')})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-7">
                        <p>{formattedDate}</p>
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="tag"><span>{postCategory}</span></div>
        </div>

        <div className="share-wrapper container">
            <ShareLinks
                url={url}
                title={title}
            />
        </div>

        <div className="container post-content">
            <div dangerouslySetInnerHTML={{ __html: items.content.rendered }} />
            {/* <div className="row">
                <div className="col-12">
                    {postContent[1][0][0] === null ? '' : <Heading Heading={postContent[1][0][0]} Tag={'h3'}/>}
                    {postContent[1][0][1] && <Paragraph Paragraphs={postContent[1][0][1]['paragraphs'][0]} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {postContent[2][0] &&  <Heading Heading={postContent[2][0][0]} Tag={'h2'}/>}
                    {postContent[2][1][2] && <Paragraph Paragraphs={postContent[2][1][2]['paragraphs'][0]} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                  {postContent[3][0] && <Paragraph Paragraphs={postContent[3][0]} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                  {postContent[4][0][0] && <Heading Heading={postContent[4][0][0]} Tag={'h3'}/>}
                  {postContent[4][1] && <Paragraph Paragraphs={postContent[4][1]} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {postContent[5][0] && <div dangerouslySetInnerHTML={{__html: postContent[5][0]}} />}
                </div>
            </div> */}
        </div>


        <div className="post-links">
            {items.previous &&
                <Link className='prev' to={'/news/'+items.previous.slug} >
                    <i className="icon"></i> 
                    <div>
                        <p>Previous post</p>
                        <h4 dangerouslySetInnerHTML={{ __html: items.previous.title }} />
                    </div>
                    <img src={items.previous.thumbnail} alt={items.previous.title} />
                </Link>
            }
            {items.next &&
                <Link className='next ml-auto' to={'/news/'+items.next.slug} >
                    <div>
                        <p>Next post</p>
                        <h4 dangerouslySetInnerHTML={{ __html: items.next.title }} />
                    </div>
                    <i className="icon"></i>
                    <img src={items.next.thumbnail} alt={items.next.title} />
                </Link>
            }
        </div>
    </div>
  )
}

export default NewsSingle