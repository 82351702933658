import React, { useRef, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import VideoModal from '../components/VideoModal';
import { PageData } from "../types/PageData";
import NextSection from '../components/NextSection';
import TeamSlider from '../components/TeamSlider';
import Cta from '../components/Cta';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";
import {motion} from "framer-motion";

function AboutUs() {
    const elementRef = useRef<HTMLDivElement>(null);
    const elementRef2 = useRef<HTMLDivElement>(null);
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;

    const imgRegex = /.*src="([^"]*)".*/;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

    let previousY = 0
    let previousRatio = 0
    const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry) => {
            const currentY = entry.boundingClientRect.y;
            const currentRatio = entry.intersectionRatio;
            if(currentY < previousY){
                if(currentRatio> previousRatio && entry.isIntersecting){
                    entry.target.classList.remove('animate');
                }
            } else if(currentY > previousY && entry.isIntersecting){
                if(currentRatio > previousRatio){
                    entry.target.classList.add('animate');
                }
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);

    useEffect(() => {

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
          };

          const observer = new IntersectionObserver(handleIntersection, options);

          if (elementRef.current) {
            observer.observe(elementRef.current);
          }

          if (elementRef2.current) {
            observer.observe(elementRef2.current);
          }

          return () => observer.disconnect();
    }, []);

    const [inView, setInView] = useState(false);
    const containerRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [containerRef]);

    const id = window.location.hash.substring(1);

    useEffect(() => {
        if (id) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: "auto" });
            }
        }
    }, []);
    console.log(items)
  return (
    <div className='about-us'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <div className='text-banner' style={{backgroundImage: `url(${items[0][7][0].replace(imgRegex, '$1')})`}}>
            <div className={`container ${inView && 'animating'}`} ref={containerRef}>
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-7 text-banner__heading">
                        {PageData.title && <motion.h1 initial={{opacity: 0, x: '-5%'}}
                                                   style={{overflow: 'hidden', display: 'block'}}
                                                   animate={inView && {opacity: 1, x: '0'}}
                                                   transition={{duration: 0.8}}
                                                   dangerouslySetInnerHTML={{__html: PageData.title.rendered}} />}
                    </div>
                    <div className="col-12 col-md-8 col-lg-5 text-banner__content">
                        {items[0][0] && <Paragraph className={'heading'} Paragraphs={items[0][0].join('')}/>}
                        <div className="stats">
                            <div className="stat">
                                <div className="image">
                                    {items[0][1][0] && <div dangerouslySetInnerHTML={{__html: items[0][1][0]}} />}
                                </div>
                                <div className="text">
                                    {items[0][2] && <div dangerouslySetInnerHTML={{__html: items[0][2].join('')}} />}
                                </div>
                            </div>
                            <div className="stat">
                                <div className="number">
                                    {items[0][3] && <div dangerouslySetInnerHTML={{__html: items[0][3].join('')}} />}
                                </div>
                                <div className="text">
                                    {items[0][4] && <div dangerouslySetInnerHTML={{__html: items[0][4].join('')}} />}
                                </div>
                            </div>
                            <div className="stat">
                                <div className="number">
                                    {items[0][5] && <div dangerouslySetInnerHTML={{__html: items[0][5].join('')}} />}
                                </div>
                                <div className="text">
                                    {items[0][6] && <div dangerouslySetInnerHTML={{__html: items[0][6][0]+items[0][6][1]['paragraphs']}} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container intro-content"> 
            <div className="row">
                <div className="col-12 col-md-6 col-lg-7">
                    {/* <div className="heading">

                        {items[1][0][0] && <Heading Tag={'h2'} Heading={items[1][0][0]} />}
                        {items[1][0][1] && <Paragraph Paragraphs={items[1][0][1]} />}
                    </div>
                    <div className="text">
                        {items[1][1][0] && <Paragraph Paragraphs={items[1][1][0]} />}
                        {items[1][1][1] && <Paragraph Paragraphs={items[1][1][1]} />}
                    </div> */}

                    <div className="logos">
                        <div className="div">
                            {items[1][2][0] && <div dangerouslySetInnerHTML={{__html: items[1][2][0]}} />}
                        </div>
                        <div className="images">
                            <div className="logo">
                                {items[1][3][0] && <div dangerouslySetInnerHTML={{__html: items[1][3][0]}} />}
                                {items[1][3][1] && <div dangerouslySetInnerHTML={{__html: items[1][3][1]}} />}
                                {items[1][3][14] && <div dangerouslySetInnerHTML={{__html: items[1][3][14]['paragraphs'][0]}} />}
                            </div>
                            <div className="logo">
                                {items[1][3][2] && <div dangerouslySetInnerHTML={{__html: items[1][3][2]}} />}
                                {items[1][3][3] && <div dangerouslySetInnerHTML={{__html: items[1][3][3]}} />}
                                {items[1][3][14] && <div dangerouslySetInnerHTML={{__html: items[1][3][14]['paragraphs'][1]}} />}
                            </div>
                            <div className="logo">
                                {items[1][3][4] && <div dangerouslySetInnerHTML={{__html: items[1][3][4]}} />}
                                {items[1][3][5] && <div dangerouslySetInnerHTML={{__html: items[1][3][5]}} />}
                                {items[1][3][14] && <div dangerouslySetInnerHTML={{__html: items[1][3][14]['paragraphs'][2]}} />}
                            </div>
                            <div className="logo">
                                {items[1][3][6] && <div dangerouslySetInnerHTML={{__html: items[1][3][6]}} />}
                                {items[1][3][7] && <div dangerouslySetInnerHTML={{__html: items[1][3][7]}} />}
                                {items[1][3][14] && <div dangerouslySetInnerHTML={{__html: items[1][3][14]['paragraphs'][3]}} />}
                            </div>
                            <div className="logo">
                                {items[1][3][8] && <div dangerouslySetInnerHTML={{__html: items[1][3][8]}} />}
                                {items[1][3][9] && <div dangerouslySetInnerHTML={{__html: items[1][3][9]+items[1][3][10]}} />}
                            </div>
                            <div className="logo">
                                {items[1][3][11] && <div dangerouslySetInnerHTML={{__html: items[1][3][11]}} />}
                                {items[1][3][12] && <div dangerouslySetInnerHTML={{__html: items[1][3][12]+items[1][3][13]}} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="liberation">
            <VideoModal 
                videoThumbnail={items[2][0]}
                video={items[2][1]+items[2][2]}
                placeholder={items[2][3][0].replace(imgRegex, '$1')}
            />
        </div>

        <div className="component founders">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5">
                        {items[3][0][0] && <Heading Tag={'h2'} Heading={items[3][0][0]} />}
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className="row right-text" style={{width: '100%'}}>
                    <div ref={elementRef} className="col-12 col-md-6 order-1 image">
                        {items[3][1][0] && <div dangerouslySetInnerHTML={{__html: items[3][1][0]}} />}
                    </div>
                    <div className="col-12 col-md-6 text order-2">
                        <div className="heading">
                            {items[3][2][0] && <Paragraph Paragraphs={items[3][2][0]} />}
                            {items[3][2][1] && <Paragraph Paragraphs={items[3][2][1]['paragraphs'][0]} />}
                        </div>
                        <div className="copy">
                            {items[3][2][1] && <Paragraph Paragraphs={items[3][2][1]['paragraphs'][1]} />}
                        </div>
                    </div>
                </div>
                <div className="row left-text" style={{width: '100%'}}>
                    <div className="col-12 col-md-6 order-2 order-md-1 text">
                        <div className="heading">
                            {items[3][4][0] && <Paragraph Paragraphs={items[3][4][0]} />}
                            {items[3][4][1] && <Paragraph Paragraphs={items[3][4][1]['paragraphs'][0]} />}
                        </div>
                        <div className="copy">
                            {items[3][4][1] && <Paragraph Paragraphs={items[3][4][1]['paragraphs'][1]} />}
                            {items[3][4][1] && <Paragraph Paragraphs={items[3][4][1]['paragraphs'][2]} />}
                        </div>
                    </div>
                    <div ref={elementRef2} className="col-12 col-md-6 order-1 order-md-2 image">
                        {items[3][3][0] && <div dangerouslySetInnerHTML={{__html: items[3][3][0]}} />}
                    </div>
                </div>
            </div>
            <div className="container">
                <NextSection 
                    title='THE TEAM'
                    sectionAnchor='#the-team'
                    color='light'
                />
            </div>
        </div>

        <div className="component" id="the-team">
            <div className="container">
                <div className="row intro-content">
                    <div className="col-12 col-md-10 col-lg-8">
                        {items[4][0][0] && <Heading Tag={'h2'} Heading={ items[4][0][0]} />}
                        {items[4][0][1] && <Paragraph Paragraphs={items[4][0][1]} />}
                    </div>
                </div>
            </div>
            <TeamSlider />
            <div className="container heritage">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-7 ml-auto">
                        {items[5][0][0] && <Heading Tag={'h2'} Heading={items[5][0][0]} />}
                        {items[5][0][1] && <Paragraph Paragraphs={items[5][0][1]} />}
                        <div className="logos">
                            <div className="logo">
                                <div className="image">
                                    {items[5][1][0] && <div dangerouslySetInnerHTML={{__html: items[5][1][0]}} />}
                                </div>
                                <div className="text">
                                    {items[5][1][1] && <div dangerouslySetInnerHTML={{__html: items[5][1][1]}} />}
                                    {items[5][1][4] && <div dangerouslySetInnerHTML={{__html: items[5][1][4]['paragraphs']}} />}
                                    {items[5][1][2] && <p dangerouslySetInnerHTML={{__html: items[5][1][2]}} />}
                                </div>
                            </div>
                            <div className="logo">
                                <div className="image">
                                    {items[5][2][0] && <div dangerouslySetInnerHTML={{__html: items[5][2][0]}} />}
                                </div>
                                <div className="text">
                                    {items[5][2][1] && <div dangerouslySetInnerHTML={{__html: items[5][2][1]}} />}
                                    {items[5][2][4] && <div dangerouslySetInnerHTML={{__html: items[5][2][4]['paragraphs']}} />}
                                    {items[5][2][2] && <p dangerouslySetInnerHTML={{__html: items[5][2][2]}} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <Cta 
            background={items[6][0].replace(imgRegex, '$1')}
            title={items[6][1][0]}
            copy={items[6][2][2]}
            buttonHref={items[6][2][0].replace(aiButtonHref, '$1')}
            buttonText={items[6][2][0].replace(aiButtonHref, '$2')}
        /> */}
    </div>
  )
}

export default AboutUs